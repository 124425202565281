import React, { useState } from "react";
import JargonSearch from "./components/JargonSearch";
import JargonList from "./components/JargonList";
import jargonData from "./data/jargonData.json";
import { APP_DESCRIPTION, APP_TITLE, NO_RESULTS_MESSAGE, SEARCH_PLACEHOLDER } from "./constants/jargonConstants";
import { JargonTermType } from "./types/JargonTypes";
import "./styles/App.css";

/**
 * App component manages the state and logic of the Jargon Buster tool.
 * It handles the search functionality and renders the JargonSearch and JargonList components.
 *
 */
const App: React.FC = () => {
  // Define state to store filtered terms
  const [filteredTerms, setFilteredTerms] = useState<JargonTermType[]>([]);

  /**
   * Handles the search query and filters the jargon terms based on the query.
   *
   * @param {string} query The search query.
   */
  const handleSearch = (query: string) => {
    if (query) {
      const results = jargonData.filter((term: JargonTermType) =>
        term.term.toLowerCase().includes(query.toLowerCase()),
      );
      setFilteredTerms(results);
    } else {
      setFilteredTerms([]); // Clear the results if the query is empty
    }
  };

  return (
    <div className="app-container">
      <h1 className="app-title">{APP_TITLE}</h1>
      <p className="app-description">{APP_DESCRIPTION}</p>
      <JargonSearch onSearch={handleSearch} placeholder={SEARCH_PLACEHOLDER} />
      <JargonList terms={filteredTerms} noResultsMessage={NO_RESULTS_MESSAGE} />
    </div>
  );
};

export default App;
