/**
 * Constants for the Jargon Buster app.
 * This file contains static text content, messages, and any other constants.
 */

// Description text for the app
export const APP_DESCRIPTION = `Welcome to the Jargon Buster! This tool helps you decode the complex and confusing jargon used in various fields. Simply type a term into the search box, and we'll show you the definition.`;

// Default "no results" message
export const NO_RESULTS_MESSAGE = "No results found";

// Placeholder text for the search input
export const SEARCH_PLACEHOLDER = "Search for a term...";

// Title of the application
export const APP_TITLE = "Jargon Buster";
