import React from "react";
import { JargonTermType } from "../types/JargonTypes";
import JargonTerm from "./JargonTerm";

function getLiElement(index: number, term: JargonTermType) {
  return (
    <li key={index}>
      <JargonTerm term={term} />
    </li>
  );
}

/**
 * Renders the list of jargon terms or a message if the list is empty.
 * @param {JargonTermType[]} terms The list of jargon terms to display.
 * @param noResultsMessage Message to appear if there are no results
 * @returns {JSX.Element} The rendered list or a message indicating no terms were found.
 */
const renderJargonTerms = (
  terms: JargonTermType[],
  noResultsMessage: string,
): JSX.Element => {
  if (terms.length === 0) {
    return <p>{noResultsMessage}</p>;
  }

  return (
    <ul className="jargon-list">
      {terms.map((term, index) => getLiElement(index, term))}
    </ul>
  );
};

/**
 * Sorts the terms and definitions by A To Z
 * @param terms Terms to sort
 */
function sortTermsByUsingAToZSorting(terms: JargonTermType[]) {
  const sortedTerms = [...terms].sort((a, b) => a.term.localeCompare(b.term));
  return sortedTerms.map((term) => {
    if (Array.isArray(term.definition)) {
      const sortedDefinitions = [...term.definition].sort((a, b) =>
        a.localeCompare(b),
      );
      return { ...term, definition: sortedDefinitions };
    }
    return term;
  });
}

/**
 * JargonList component that renders a list of jargon terms.
 *
 */
const JargonList: React.FC<{
  terms: JargonTermType[];
  noResultsMessage: string;
}> = ({ terms, noResultsMessage }) => {
  const sortedTerms = sortTermsByUsingAToZSorting(terms);
  return (
    <div className="jargon-list-container">
      {renderJargonTerms(sortedTerms, noResultsMessage)}
    </div>
  );
};

export default JargonList;
