import React, { useState } from "react";

/**
 * Creates a search button to use.
 * @param handleSearchClick What to do on click
 * @param query The Query to search with, and make the button disabled if it isn't populated
 */
function createSearchButton(handleSearchClick: () => void, query: string) {
  return (
    <button
      onClick={handleSearchClick}
      className="search-button"
      disabled={!query}>
      Search
    </button>
  );
}

/**
 * Creates an input object.
 * @param query Value for the input
 * @param handleChange What to do on Change
 * @param placeholder Placeholder text in case no results were found
 */
function createInputArea(
  query: string,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder: string,
) {
  return (
    <input
      type="text"
      value={query}
      onChange={handleChange}
      placeholder={placeholder}
      className="search-input"
    />
  );
}

/**
 * JargonSearch component handles the user input for searching jargon terms.
 * It triggers the search when the button is clicked.
 */
const JargonSearch: React.FC<{
  onSearch: (query: string) => void;
  placeholder: string;
}> = ({ onSearch, placeholder }) => {
  const [query, setQuery] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSearchClick = () => {
    onSearch(query);
  };

  return (
    <div className="search-container">
      {createInputArea(query, handleChange, placeholder)}
      {createSearchButton(handleSearchClick, query)}
    </div>
  );
};

export default JargonSearch;
