import React from "react";
import { JargonTermType } from "../types/JargonTypes";

/**
 * JargonTerm component displays a single jargon term with its definition.
 */
const JargonTerm: React.FC<{ term: JargonTermType }> = ({ term }) => {
  return (
    <div className="jargon-term">
      <h3 className="term-title">{term.term}</h3>
      <div className="term-definition">
        {Array.isArray(term.definition) ? (
          // If the definition is an array, map over it and display each item
          term.definition.map((def, index) => <p key={index}>{def}</p>)
        ) : (
          // If the definition is a single string, display it as is
          <p>{term.definition}</p>
        )}
      </div>
    </div>
  );
};

export default JargonTerm;
